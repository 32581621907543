import * as api from "../../../server/index";

export const getConversationList = (id) => async (dispatch) => {
  dispatch({ type: "FETCH_GET_CONVERSATION_LIST_LOADING" });
  try {
    const { data } = await api.getConversationList(id);
    dispatch({ type: "FETCH_GET_CONVERSATION_LIST_DONE", payload: data });

  } catch (error) {
    dispatch({ type: "FETCH_GET_CONVERSATION_LIST_FAILED" });
  }
};
