import React from "react";
import styled from "styled-components";

const Typography = ({
  onClick, 
  children,
  cursor,
  size,
  weight,
  textAlign,
  color, 
  padding,    
  margin,
  lineHeight,
  letterSpacing, 
  position,
  top,
  left,
  Zindex,
  border,
  borderRadius,
  width,

  mssize,
  msweight,
  mstextAlign,
  mscolor,
  mspadding,
  msmargin,
  mslineHeight,
  msletterSpacing,

  mlsize,
  mlweight,
  mltextAlign,
  mlcolor,
  mlpadding,
  mlmargin,
  mllineHeight,
  mlletterSpacing,

  mxsize,
  mxweight,
  mxtextAlign,
  mxcolor,
  mxpadding,
  mxmargin,
  mxlineHeight,
  mxletterSpacing,

  tsize,
  tweight,
  ttextAlign,
  tcolor,
  tpadding,
  tmargin,
  tlineHeight,
  tletterSpacing,

  rtextAlign,
  rsize,
  rlineHeight,
  rpadding,
  rletterSpacing,
  rweight,
  transition,
  hoverColor,
  hoverLetterSpacing
}) => {
  return (
    <Text
      size={size}
      weight={weight}
      textAlign={textAlign}
      color={color}
      padding={padding}
      margin={margin}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      cursor={cursor}
      onClick={onClick}
      position={position}
      top={top}
      left={left}
      Zindex={Zindex}
      border={border}
      borderRadius={borderRadius}
      width={width}

      mssize={mssize}
      msweight={msweight}
      mstextAlign={mstextAlign}
      mscolor={mscolor}
      mspadding={mspadding}
      msmargin={msmargin}
      mslineHeight={mslineHeight}
      msletterSpacing={msletterSpacing}
      mlsize={mlsize}
      mlweight={mlweight}
      mltextAlign={mltextAlign}
      mlcolor={mlcolor}
      mlpadding={mlpadding}
      mlmargin={mlmargin}
      mllineHeight={mllineHeight}
      mlletterSpacing={mlletterSpacing}
      mxsize={mxsize}
      mxweight={mxweight}
      mxtextAlign={mxtextAlign}
      mxcolor={mxcolor}
      mxpadding={mxpadding}
      mxmargin={mxmargin}
      mxlineHeight={mxlineHeight}
      mxletterSpacing={mxletterSpacing}
      tsize={tsize}
      tweight={tweight}
      ttextAlign={ttextAlign}
      tcolor={tcolor}
      tpadding={tpadding}
      tmargin={tmargin}
      tlineHeight={tlineHeight}
      tletterSpacing={tletterSpacing}
      rtextAlign={rtextAlign}
      rsize={rsize}
      rlineHeight={rlineHeight}
      rpadding={rpadding}
      rletterSpacing={rletterSpacing}
      rweight={rweight}
      transition={transition}
      hoverColor={hoverColor}
      hoverLetterSpacing={hoverLetterSpacing}
    >
      {children}
    </Text>
  );
};

export default Typography;

export const Text = styled.p`
  font-size: ${({ size }) => (size ? size : null)};
  font-weight: ${({ weight }) => (weight ? weight : null)};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "center")};
  color: ${({ color }) => (color ? color : null)};
  padding: ${({ padding }) => (padding ? padding : null)};
  margin: ${({ margin }) => (margin ? margin : null)};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : null)};
  letter-spacing: ${({ letterSpacing }) => letterSpacing ? letterSpacing : null};
  direction: rtl;
  cursor: ${({ cursor }) => (cursor ? cursor : null)};
  position: ${({ position }) => (position ? position : null)};
  top: ${({top}) => (top ? top : null)};
  left: ${({left}) => (left ? left : null)};
  z-index: ${({ Zindex }) => (Zindex ? Zindex : null)};
  border:${({border}) => (border ? border : null)} ;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : null)};
  width: ${({ width }) => (width ? width : null)};
  transition: ${({transition}) => (transition ? transition : null)};

  &:hover{
    color:${({ hoverColor }) => (hoverColor ? hoverColor : null)}; ;
    letter-spacing: ${({hoverLetterSpacing}) => (hoverLetterSpacing ? hoverLetterSpacing : null)};

  }
  //Responsive

  //ms
  @media screen and (max-width: 319px) {
    font-size: ${({ mssize }) => (mssize ? mssize : null)};
    font-weight: ${({ msweight }) => (msweight ? msweight : null)};
    text-align: ${({ mstextAlign }) => (mstextAlign ? mstextAlign : "center")};
    color: ${({ mscolor }) => (mscolor ? mscolor : null)};
    padding: ${({ mspadding }) => (mspadding ? mspadding : null)};
    margin: ${({ msmargin }) => (msmargin ? msmargin : null)};
    line-height: ${({ mslineHeight }) => (mslineHeight ? mslineHeight : null)};
    letter-spacing: ${({ msletterSpacing }) =>
      msletterSpacing ? msletterSpacing : null};
  }

  //ml
  @media screen and (min-width: 320px) and (max-width: 479px) {
    font-size: ${({ mlsize }) => (mlsize ? mlsize : null)};
    font-weight: ${({ mlweight }) => (mlweight ? mlweight : null)};
    text-align: ${({ mltextAlign }) => (mltextAlign ? mltextAlign : "center")};
    color: ${({ mlcolor }) => (mlcolor ? mlcolor : null)};
    padding: ${({ mlpadding }) => (mlpadding ? mlpadding : null)};
    margin: ${({ mlmargin }) => (mlmargin ? mlmargin : null)};
    line-height: ${({ mllineHeight }) => (mllineHeight ? mllineHeight : null)};
    letter-spacing: ${({ mlletterSpacing }) =>
      mlletterSpacing ? mlletterSpacing : null};
  }

  //mx
  @media screen and (min-width: 480px) and (max-width: 767px) {
    font-size: ${({ mxsize }) => (mxsize ? mxsize : null)};
    font-weight: ${({ mxweight }) => (mxweight ? mxweight : null)};
    text-align: ${({ mxtextAlign }) => (mxtextAlign ? mxtextAlign : "center")};
    color: ${({ mxcolor }) => (mxcolor ? mxcolor : null)};
    padding: ${({ mxpadding }) => (mxpadding ? mxpadding : null)};
    margin: ${({ mxmargin }) => (mxmargin ? mxmargin : null)};
    line-height: ${({ mxlineHeight }) => (mxlineHeight ? mxlineHeight : null)};
    letter-spacing: ${({ mxletterSpacing }) =>
      mxletterSpacing ? mxletterSpacing : null};
  }

  //t
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ tsize }) => (tsize ? tsize : null)};
    font-weight: ${({ tweight }) => (tweight ? tweight : null)};
    text-align: ${({ ttextAlign }) => (ttextAlign ? ttextAlign : "center")};
    color: ${({ tcolor }) => (tcolor ? tcolor : null)};
    padding: ${({ tpadding }) => (tpadding ? tpadding : null)};
    margin: ${({ tmargin }) => (tmargin ? tmargin : null)};
    line-height: ${({ tlineHeight }) => (tlineHeight ? tlineHeight : null)};
    letter-spacing: ${({ tletterSpacing }) =>
      tletterSpacing ? tletterSpacing : null};
  }

  //r

  @media screen and (max-width: 1023px) {
    text-align: ${({ rtextAlign }) => (rtextAlign ? rtextAlign : null)};
    font-size: ${({ rsize }) => (rsize ? rsize : null)};
    line-height: ${({ rlineHeight }) => (rlineHeight ? rlineHeight : null)};
    padding: ${({ rpadding }) => (rpadding ? rpadding : null)};
    letter-spacing: ${({ rletterSpacing }) =>
      rletterSpacing ? rletterSpacing : null};
    font-weight: ${({ rweight }) => (rweight ? rweight : null)};
  }
`;