const initialState = {
    status: "idle",
    listArray: [],
  };
  
  export const getConversationInfoReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_GET_CONVERSATION_INFO_LOADING":
        return {
          ...state,
          status: "pending",
        };
      case "FETCH_GET_CONVERSATION_INFO_DONE":
        return {
          ...state,
          status: "success",
          listArray: action.payload,
        };
      case "FETCH_CONVERSATION_INFO_FAILED":
        return {
          ...state,
          status: "failed",
        };
      default:
        return state;
    }
  };
  