import * as api from "../../../server/index";

export const getProfile = () => async (dispatch) => {
  dispatch({ type: "FETCH_GET_PROFILE_LOADING" });
  try {
    const {data} =  await api.getProfile()
    dispatch({ type: "FETCH_GET_PROFILE_DONE" , payload : data});
    localStorage.setItem("information", JSON.stringify( data?.data));


  } catch (error) {
    dispatch({ type: "FETCH_GET_PROFILE_FAILED" });

  }
};
