import React from "react";
import Wrapper from "../common/Wrapper";
import men from "../../assets/images/men.png";
import styled from "styled-components";
import Span from "../common/Span";
const UserCard = ({ id, age, name, gender }) => {
  return (
    <Wrapper
      background="#fff"
      borderRadius="1rem"
      padding="1rem"
      boxShadow="6px 10px 18px 3px rgba(208, 209, 210, 1);"
    >
      <Wrapper width="20%" rwidth="20%">
     
          <Img src={men} />
      </Wrapper>
      <Wrapper>
        <Wrapper direction="column" gap="1rem" rdirection="column">
          <Span color="gray">ID</Span>
          <Span>{id}</Span>
        </Wrapper>
        <Wrapper direction="column" gap="1rem" rdirection="column">
          <Span color="gray">Name</Span>
          <Span>{name}</Span>
        </Wrapper>
        <Wrapper direction="column" gap="1rem" rdirection="column">
          <Span color="gray">Gender</Span>
          <Span>{gender}</Span>
        </Wrapper>{" "}
        <Wrapper direction="column" gap="1rem" rdirection="column">
          <Span color="gray">Age</Span>
          <Span>{age}</Span>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default UserCard;

const Img = styled.img`
  width: 5rem;
  height: 5rem;
`;
