import React from 'react'
import Wrapper from '../components/common/Wrapper'
import MessageBox from '../components/Message/MessageBox'

const MessagePage = () => {
  return (
    <Wrapper height="100vh">
      <Wrapper margin="50% auto" width="60%" rwidth="90%" >

      <MessageBox/>
      </Wrapper>
    </Wrapper>
  )
}

export default MessagePage 