const initialState = {
    status: "idle",
  };
  
  export const sendOtpReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_OTP_LOADING":
        return {
          ...state,
          status: "pending",
        };
      case "FETCH_OTP_DONE":
        return {
          ...state,
          status: "success",
        };
      case "FETCH_OTP_FAILED":
        return {
          ...state,
          status: "failed",
        };
      default:
        return state;
    }
  };
  