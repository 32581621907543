import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Wrapper from "../components/common/Wrapper";
import Span from "../components/common/Span";
import { useDispatch, useSelector } from "react-redux";
import { getReport } from "../service/actions/Profile/getReport";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { getPatientList } from "../service/actions/Profile/getPatientList";
import men from "../assets/images/men.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {RiRedPacketLine} from "react-icons/ri"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale
);

// export const labels = ['January', 'February', 'March', 'April', 'May']

const ChartPage = () => {
  const getRepo = useSelector((state) => state?.getReport?.listArray);
  const listUsers = useSelector((state) => state?.getPatientList?.listArray);


  const dispatch = useDispatch();
  const navigate = useNavigate()
  const messageHandler = () => {
    navigate("/dashboard/message");
    localStorage.setItem("userId", JSON.stringify( id));

  }
  const { id } = useParams();
  const userInfo = {
    userId: id,
  };


  const userInformation = listUsers?.filter((item) => item?.id === id);

  console.log("userInformation", userInformation);
  // chart-js-config
  const data = {
    labels: getRepo?.map((item) => moment(item?.date).format("YYYY-MM-DD")),

    datasets: [
      {
        label: "My First Dataset",
        data: getRepo?.map((item) => item?.score),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
        barThickness: 20,
      },
    ],
  };

  const options = {
    scales: {
      indexAxis: "y", // Set indexAxis to 'y' for vertical labels on the Y-axis
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        suggestedMin: 5,
        suggestedMax: 10,
        // type: "logarithmic",
      },
    },
  };

  const chartStyle = {
    width: "100%", // Set the width as needed
    // width: `${getRepo.length * 35}rem`,
    padding: "2rem",
    // height: '100vh', // Set the height as needed
  };

  // mounting
  useEffect(() => {
    dispatch(getReport(userInfo));
    dispatch(getPatientList());
  }, []);

  console.log("listUsers", listUsers);

  return (
    <Wrapper height="100vh">
      <Wrapper
        justifyContent="flex-start"
        alignItem="flex-start"
        width="80%"
        background="#fff"
        padding="2rem"
        borderRadius="0.5rem"
      >
        <Wrapper direction="column" width="25%">
          <Img src={men} />
          <Wrapper
            width="60%"
            // border="1px solid "
            margin="1rem"
            padding="0.6rem"
            cursor="pointer"
            background="rgb(84 91 254)"
            borderRadius="0.5rem" 
            justifyContent="space-evenly"
            onClick={messageHandler}
          >
            <RiRedPacketLine color="#fff" size="2rem" />
            <Span color="#fff">

              Messages
            </Span>
           
          </Wrapper>
        </Wrapper>

        <Wrapper direction="column">
          <Wrapper>
            {userInformation?.map((item) => (
              <Wrapper direction="column" width="80%" gap="1rem">
                <Wrapper justifyContent="space-between">
                  <Span width="1rem" color="gray">ID</Span>
                  <Span width="1rem" color="gray">Name</Span>
                  <Span width="1rem" color="gray">Gender</Span>
                  <Span width="1rem" color="gray">Age</Span>
                </Wrapper>
                <Wrapper border="1px solid #c1c1c1"></Wrapper>
                <Wrapper justifyContent="space-between">
                  <Span width="1rem" >{item?.id}</Span>
                  <Span width="1rem" >{item?.name}</Span>
                  <Span width="1rem">{item?.gender}</Span>
                  <Span width="1rem">{item?.age}</Span>
                </Wrapper>
              </Wrapper>
            ))}
          </Wrapper>
          <Wrapper>
            <Bar data={data} options={options} style={chartStyle} />
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default ChartPage;

const Img = styled.img`
  width: 10rem;
  height: 10rem;
`;
