import React from "react";
import Wrapper from "../common/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import styled from "styled-components";

const DashboardLayout = ({ children }) => {
  const dispatch = useDispatch();

  const closeMenuHandler = () => {
    dispatch();
  };
  return (
    <Wrapper background="rgb(223 230 233)">
      <Wrapper
        direction="column"
        rdirection="column"
        height="100vh"
        // background="#c1c1c1"
        overflowX="hidden"
        rheight="100vh"
      >
        <Wrapper
          // rdirection="column-reverse"
          overflowX="hidden"
        >
          <Sidebar />
          <Wrapper
            height="100vh"
            direction="column"
            justifyContent="flex-start"
            padding="0 1rem 0 0"
            overflowY="scroll"
            overflowX="hidden"
            // background="red"

            // rpadding="0"
            rjustifyContent="flex-start"
            rheight="100vh"
            rdirection="column"
          >
            {children}
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default DashboardLayout;

export const Main = styled.div`
  display: flex;
  width: 100%;
  /* height: 100vh; */
  background-image: ${(props) =>
    props.backgroundImage ? `url(${props.backgroundImage})` : "none"};
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;

  @media screen and (max-width: 1023px) {
    /* height: 100vh; */
    /* background-color:red; */
    /* background-image:none; */
  }
`;
