import * as api from "../../../server/index";

export const signUpSendFinalize = (signUpFinalize) => async (dispatch) => {
  dispatch({ type: "FETCH_SIGNUP_FINALIZE_LOADING" });

  try {
    const { data } = await api.signUpFinalize(signUpFinalize);
    dispatch({ type: "FETCH_SIGNUP_FINALIZE_DONE", payload: data });
    window.location.href = '/dashboard'  

  } catch (error) {
    dispatch({ type: "FETCH_SIGNUP_FINALIZE_ERROR", payload: error });
  }
};
