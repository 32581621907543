export const validate = (values, rules) => {
  let errors = {};

  Object.keys(rules).forEach((field) => {
    const rulesForField = rules[field];

    if (
      rulesForField.required &&
      (!values[field] || values[field].trim() === "")
    ) {
      errors[field] = ` ${field === "title" ?
       "Title" : 
       field === "cid" ?
       "Conversation List" :
      field} is required`;
    }

    if (
      rulesForField.minLength &&
      values[field] &&
      values[field].length < rulesForField.minLength
    ) {
      errors[field] = `Minimum ${rulesForField.minLength} characters`;
    }

    if (
      rulesForField.regex &&
      values[field] &&
      !rulesForField.regex.test(values[field])
    ) {
      errors[field] = rulesForField.errorMessage || "Invalid format";
    }
  });

  return errors;
};
