import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../common/Button";
import Input from "../common/Input";
import Span from "../common/Span";
import Wrapper from "../common/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import { addConversation } from "../../service/actions/Message/addConversation";
import { getProfile } from "../../service/actions/Profile/getProfile";
import { getConversationList } from "../../service/actions/Message/getConversationList";
import { Select } from "antd";
import { sendMessage } from "../../service/actions/Message/sendMessage";
import { validate } from "../../helper/validation";
import { getConversationInfo } from "../../service/actions/Message/getConversationInfo";
import Modal from "antd/es/modal/Modal";

const MessageBox = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  // modal
  const [openModal, setOpenModal] = useState(false);
  const closeModalHandler = () => {
    setOpenModal(false);
  };
  // CONVERSATION_INFO
  const conversationInfo = useSelector(
    (state) => state?.getConversationInfo?.listArray?.data
  );
  console.log("conversationInfo", conversationInfo);

  // CONVERSATION_LIST
  const conversationList = useSelector(
    (state) => state?.getConversationList?.listArray?.data
  );
  // select
  const [conversationTitle, setConversationTitle] = useState({
    cId: null,
  });

  const [idInfo, setIdInfo] = useState({
    id: null,
  });

  const selectTitleHandler = (value) => {
    setIdInfo({
      id: value,
    });

    setConversationTitle({
      cId: value,
    });
  };

  const Option = conversationList?.map((item) => ({
    label: item?.title,
    value: item?.Id,
  }));

  // ADD_CONVERSATION
  const userId = JSON.parse(localStorage.getItem("userId"));
  const adminId = JSON.parse(localStorage.getItem("information"))?.id;

  console.log("adminId", adminId);

  const [addConversationInfo, setAddConversationInfo] = useState({
    title: "",
    from: adminId,
    to: userId,
  });
  const addConversationChangeHandler = (e) => {
    const { name, value } = e.target;
    setAddConversationInfo({
      ...addConversationInfo,
      [name]: value,
    });
  };
  const addConversationHandler = () => {
    const values = addConversationInfo;
    const rules = {
      title: {
        required: true,
      },
    };
    const validationTitle = validate(values, rules);
    setErrors(validationTitle);

    if (Object.keys(validationTitle).length === 0) {
      dispatch(addConversation(addConversationInfo));
      setAddConversationInfo({
        title: "",
        from: adminId,
        to: userId,
      });
    }
  };

  // MESSAGE_TEXT
  const [messageInfo, setMessageInfo] = useState({
    mesage: "",
    sender: adminId,
    receiver: userId,
    cid: conversationTitle.cId,
  });
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setMessageInfo({
      ...messageInfo,
      [name]: value,
    });
  };

  const sendHandler = () => {
    const values = messageInfo;
    const rules = {
      mesage: {
        required: true,
      },
      cid: {
        required: true,
      },
    };
    const validationMessage = validate(values, rules);
    setErrors(validationMessage);

    if (Object.keys(validationMessage).length === 0) {
      dispatch(sendMessage(messageInfo));
    }

    console.log("messageInfo", messageInfo);
  };

  console.log("EROROROR", errors);
  useEffect(() => {
    dispatch(getProfile());
    dispatch(getConversationList(userId));
  }, []);

  useEffect(() => {
    setMessageInfo((prevMessageInfo) => ({
      ...prevMessageInfo,
      cid: conversationTitle.cId,
    }));
  }, [conversationTitle.cId]);

  useEffect(() => {
    if (idInfo.id !== null) {
      dispatch(getConversationInfo(idInfo, userId));
    }
  }, [idInfo.id, userId]);

  return (
    <>
      <Wrapper
        border="1px solid #000"
        padding="2rem 4rem"
        background="#fff"
        borderRadius="1rem"
        direction="column"
        gap="2rem"
        rdirection="column"
      > 
        <Wrapper
          justifyContent="flex-start"
          // rjustifyContent="flex-start"
          // borderBottom="1px solid gray"
          padding="1rem"
        >
          <Span size="2rem">Send Message</Span>
        </Wrapper>

        <Wrapper direction="column" alignItem="flex-start" gap="1rem">
          <Span size="1.3rem">Add Title</Span>

          <Wrapper justifyContent="space-between">
            <Wrapper direction="column" alignItem="flex-start" gap="0.5rem">
              <Input
                width="90%"
                placeholder="add title"
                borderBottom={errors?.title ? "1px solid red" : "1px solid"}
                name="title"
                value={addConversationInfo.title}
                onChange={addConversationChangeHandler}
                padding="0.3rem"
              />
              {/* {errors?.title && <Span color="red">{errors?.title}</Span>} */}
            </Wrapper>
            <Button
              onClick={addConversationHandler}
              background="rgb(11 66 108)"
              width="5%"
              color="#fff"
              borderRadius="0.5rem"
              padding="0.3rem"
            >
              +
            </Button>
          </Wrapper>
        </Wrapper>

        <Wrapper
          alignItem="flex-start"
          direction="column"
          padding="1rem"
          gap="1rem"
        >
          <Wrapper justifyContent="flex-start">
            <Span size="1.3rem">Conversation List</Span>
          </Wrapper>

          <Wrapper direction="column" alignItem="flex-start" gap="1rem">
            <Select
              options={Option}
              // mode="tags"
              style={{
                width: "100%",
                border: `${errors?.cid ? "1px solid red" : "1px solid"}`,
                borderRadius: "6px",
              }}
              placeholder="Select Title"
              onChange={selectTitleHandler}
            />
          </Wrapper>
        </Wrapper>

        <Wrapper
          direction="column"
          alignItem="flex-start"
          rdirection="column"
          ralignItem="flex-start"
        >
          <Span padding="1rem 0">Message</Span>
          <StyledTextArea
            placeholder="Type your Message"
            onChange={changeHandler}
            value={messageInfo.mesage}
            name="mesage"
            border={errors?.mesage ? "1px solid red" : "1px solid"}
          />
        </Wrapper>
        <Wrapper justifyContent="space-between">
          <Button
            onClick={sendHandler}
            background="rgb(11 66 108)"
            width="40%"
            color="#fff"
            borderRadius="2rem"
            rwidth="50%"
            padding="0.5rem"
            rpadding="0.3rem"
            rfontSize="0.8rem"
            border="1px solid #d0d1d2"
          >
            Send{" "}
          </Button>
          {idInfo.id && (
            <Button
              width="40%"
              borderRadius="2rem"
              background={"rgb(11 66 108)"}
              onClick={() => setOpenModal(true)}
              color="#fff"
              padding="0.5rem"
            >
              Show Conversation
            </Button>
          )}
        </Wrapper>

        {/* <Wrapper border="1ps solid red" direction="column" gap="1rem" >
      {
        conversationInfo?.map((item) =>(
          item?.userType === "client" ? 
          <Wrapper justifyContent="flex-start">

          <Wrapper key={item.id} justifyContent="flex-start" background="lightblue" borderRadius="1rem" padding="0.5rem" width="55%">
          <p>
          {item?.message}
          </p>
          </Wrapper>
          </Wrapper>
          :
          <Wrapper justifyContent="flex-end">
          
          <Wrapper key={item.id} justifyContent="flex-end" background="pink" borderRadius="1rem" padding="0.5rem" width="55%">
          <p>
          {item?.message}
          </p>
          </Wrapper>
          </Wrapper>
        ))
      }
      </Wrapper> */}
      </Wrapper>
      <Modal
        visible={openModal}
        onOk={closeModalHandler}
        onCancel={closeModalHandler}
        okText="متوجه شدم"
        cancelText="لغو"
        className="modal-body"
        footer={[
          <Wrapper>
            <Button
              key="ok"
              background="#d0d1d2"
              width="30%"
              borderRadius="0.5rem"
              border="3px solid #fff"
              color="#154c79"
              onClick={closeModalHandler}
              rwidth="45%"
              rfontSize="0.8rem"
            >
              close{" "}
            </Button>
          </Wrapper>,
        ]}
      >
        {/* <p>{modalText}</p> */}
        <Wrapper border="1ps solid red" direction="column" gap="1rem">
          {conversationInfo?.map((item) =>
            item?.userType === "client" ? (
              <Wrapper justifyContent="flex-start">
                <Wrapper
                  key={item.id}
                  justifyContent="flex-start"
                  background="lightblue"
                  borderRadius="1rem"
                  padding="0.5rem"
                  width="55%"
                >
                  <p>{item?.message}</p>
                </Wrapper>
              </Wrapper>
            ) : (
              <Wrapper justifyContent="flex-end">
                <Wrapper
                  key={item.id}
                  justifyContent="flex-end"
                  background="pink"
                  borderRadius="1rem"
                  padding="0.5rem"
                  width="55%"
                >
                  <p>{item?.message}</p>
                </Wrapper>
              </Wrapper>
            )
          )}
        </Wrapper>
      </Modal>
    </>
  );
};

export default MessageBox;

const StyledTextArea = styled.textarea`
  padding: 1rem;
  font-size: 1rem;
  border: ${({ border }) => (border ? border : "1px solid black")};
  border-radius: 0.5rem;
  resize: vertical; /* Allow vertical resizing */
  width: 100%;
  min-height: 8rem;
`;
