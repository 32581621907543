
export const logout = () => async (dispatch) => {
  dispatch({ type: "FETCH_LOGOUT_LOADING" });

  try {
    dispatch({ type: "FETCH_LOGOUT_DONE" });
    localStorage.clear()
    window.location.href = '/login' 
 }

     catch (error) {
    dispatch({ type: "FETCH_LOGOUT_FAILED" });

  }
};
