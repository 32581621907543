import axios from "axios";
const mentaligenceClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
export const MENTALIGENCE_API = (contentType) => {
  mentaligenceClient.interceptors.request.use(
    async (config) => {
      // setLoading(true)
      const mentaliganceInfo = localStorage.getItem("mentaliganceInfo");
      if (mentaliganceInfo && mentaliganceInfo !== '""') {
        const accessToken = JSON.parse(mentaliganceInfo);
        console.log("0000accessToken000", accessToken);
        // config.headers["Content-Type"] = contentType;
        config.headers["Authorization"] = accessToken;
        mentaligenceClient.defaults.headers.common["Content-Type"] =
          contentType;
      } else {
        // config.headers["Content-Type"] = contentType;
        mentaligenceClient.defaults.headers.common["Content-Type"] =
          contentType;

        config.headers["Authorization"] = "";
      }
      return config;
    },

    (error) => {
      console.log(error);
      Promise.reject(() => {
        // errorMessage(error, error);
      });
    }
  );

  mentaligenceClient.interceptors.response.use(
    (res) => {
      // setLoading(false)
      if (res) {
        // console.log("000res000",res);
      }
      return res;
    },
    async (error) => {
      console.log("ERROR_IN_INTERCEPTOR", error);

      const config = error.config;
      if (
        error?.response &&
        error?.response?.status === 403 &&
        !config._retry
      ) {
        config._retry = true;
        try {
          console.log("01");
          let res = await refresh_token();
          console.log("RESPONS_OF_REFRESHtoken", res);
          const acc = res?.data?.data?.accessToken;
          if (acc) {
            console.log("02");
            const user = JSON.parse(localStorage.getItem("mentaliganceInfo"));
            const newUser = { ...user, accessToken: acc };
            localStorage.setItem(
              "mentaliganceInfo",
              JSON.stringify(newUser),
              {}
            );
            mentaligenceClient.defaults.headers.common["Authorization"] = acc;
          } else {
            console.log("03");
          }

          return mentaligenceClient(config);
        } catch (err) {
          console.log("04", err);
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    }
  );
  return mentaligenceClient;
};

// REFRESH_TOKEN
const refresh_token = async () => {
  try {
    const refreshToken = JSON.parse(
      localStorage.getItem("mentaliganceInfo")
    )?.refreshToken;
    const url = `${process.env.REACT_APP_BASE_URL}refresh`;
    const data = { refreshToken };
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    localStorage.clear();
    sessionStorage.clear();

    window.location.href = "/login";
    return null;
  }
};

// login
export const login = (loginInfo) =>
  MENTALIGENCE_API("application/json").post("login", loginInfo);

// send-OTP
export const sendOtp = (otpInfo) =>
  MENTALIGENCE_API("application/json").post("emailverification", otpInfo);

//sign-up
export const signUpSendEmail = (signUpEmail) =>
  MENTALIGENCE_API("application/json").post("emailsignup", signUpEmail);

export const signUpSendOtp = (signUpOtp) =>
  MENTALIGENCE_API("application/json").post("emailverification", signUpOtp);

export const signUpFinalize = (signUpFinalize) =>
  MENTALIGENCE_API("application/json").post("signupfinalize", signUpFinalize);

// patient-list
export const getPatientList = () =>
  MENTALIGENCE_API("application/json").post("patientlist");

// getreport
export const getreport = (userId) =>
  MENTALIGENCE_API("application/json").post("getreport", userId);

// message

export const addConversation = (conversationInf) =>
  MENTALIGENCE_API("application/json").post("addconversation", conversationInf);

  export const getConversationList = (id) =>
  MENTALIGENCE_API("application/json").get(`getconversationlist/${id}`);

  export const getConversationInfo = (idInfo, userId) => MENTALIGENCE_API("application/json").post(`getconversationinfo/${userId}`,idInfo)
export const sendMessage = (messageInfo) =>
  MENTALIGENCE_API("application/json").post("addmessage", messageInfo);

//profile
export const getProfile = () =>
  MENTALIGENCE_API("application/json").get("getprofile");

  //companyCode

export const getCompanyCode = () =>
  MENTALIGENCE_API("application/json").post("getcompanycode");

