import React from "react";
import styled from "styled-components";
import Wrapper from "../components/common/Wrapper";
import { RotatingLines } from "react-loader-spinner";

const Loading = () => {
  return (
    <Main>
      <Wrapper >
        <RotatingLines
          strokeColor="#d0d1d2"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </Wrapper>
    </Main>
  ); 
};

export default Loading;

const Main = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  backdrop-filter: blur(5px);
  z-index: 999999999999;
`;
