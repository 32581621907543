import * as api from "../../../server/index";

export const signUpSendOtp = (signUpOtp) => async (dispatch) => {
  dispatch({ type: "FETCH_SIGNUP_OTP_LOADING" });

  try {
    const { data } = await api.signUpSendOtp(signUpOtp);
    dispatch({ type: "FETCH_SIGNUP_OTP_DONE", payload: data?.data });
    localStorage.setItem("mentaliganceInfo", JSON.stringify(data?.data));

    window.location.href = "/signup-finalize";

  } catch (error) {
    dispatch({ type: "FETCH_SIGNUP_OTP_ERROR", payload: error });
  }
};
