import * as api from "../../../server/index";
export const sendMessage = (messageInfo) => async (dispatch) => {
  dispatch({ type: "SEND_MESSAGE_LOADING" });
  try {
    const { data } = await api.sendMessage(messageInfo);
    dispatch({ type: "SEND_MESSAGE_DONE" });
    window.location.reload()
  } catch (error) {
    dispatch({ type: "SEND_MESSAGE_FAILED" });
  }
};
