import React, { useState } from "react";
import Wrapper from "../components/common/Wrapper";
import Input from "../components/common/Input";
//icon

import styled from "styled-components";
import Button from "../components/common/Button";
//

import { useDispatch, useSelector } from "react-redux";

import Loading from "../utils/Loading";
import Span from "../components/common/Span";
import { signUpSendEmail } from "../service/actions/Profile/signUpSendEmail";

const SignUpSendEmail = () => {
  const dispatch = useDispatch();
  const signUpEmail = useSelector((state) => state?.signUpEmail?.status);
  console.log("signUpEmail", signUpEmail);


  const [signUpInfo, setSignUpInfo] = useState({
    email: "",
  });
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setSignUpInfo({
      ...signUpInfo,
      [name]: value,
    });
  };

  const fetchsignUpInfo = () => {
    console.log("signUpInfo", signUpInfo);
    dispatch(signUpSendEmail(signUpInfo));
  };

  return (
    <Wrapper background="rgb(223 230 233)">
      {signUpEmail === "pending" && <Loading />}
      <Wrapper height="100vh">
        <Wrapper
          direction="column"
          width="25%"
          // height="60%"
          justifyContent="space-between"
          padding="3rem 1rem"
          rwidth="85%"
          // rheight="80%"
          rjustifyContent="space-evenly"
          borderRadius="1rem"
          rpadding="0.5rem"
          rdirection="column"
          rmargin="25% auto"
          border="1px solid #000"
          background="rgb(255,255,255)"
        >
          <Wrapper
            width="100%"
            rwidth="100%"
            height="100%"
            borderRadius="1rem"
            direction="column"
            rdirection="column"
            justifyContent="space-evenly"
            rjustifyContent="space-evenly"
            gap="3rem"
          >
            <Wrapper>
              <Span size="2rem" weight="bold">
                Sign up{" "}
              </Span>
            </Wrapper>

            <Wrapper
              width="80%"
              direction="column"
              rdirection="column"
              gap="3rem"
              padding="1rem"
              rpadding="1rem"
            >
              <Wrapper
                width="95%"
                justifyContent="space-between"
                rjustifyContent="space-between"
                // borderBottom="1px solid #000"
                rwidth="95%"
                direction="column"
                rdirection="column"
                gap="1rem"
                // background="red"
                // padding="0.5rem 0"
              >
                {/* <PiUser color="#000" /> */}

                <Wrapper
                  justifyContent="flex-start"
                  rjustifyContent="flex-start"
                >
                  <Span weight="bold">Email</Span>
                </Wrapper>
                <Wrapper borderBottom="1px solid #000">
                  <Input
                    value={signUpInfo.email}
                    name="email"
                    type="text"
                    placeholder="Type your email"
                    width="95%"
                    padding="0 0 0.5rem 3.5rem"
                    Rpadding="0.4rem"
                    Rwidth="95%"
                    onChange={changeHandler}
                    color="#000"
                    autoComplete="off"
                  />
                </Wrapper>
              </Wrapper>
            </Wrapper>

            <Wrapper
              width="80%"
              justifyContent="space-evenly"
              rjustifyContent="space-evenly"
            >
              <Button
                onClick={fetchsignUpInfo}
                background="rgb(11 66 108)"
                width="65%"
                color="#fff"
                borderRadius="2rem"
                rwidth="50%"
                padding="0.5rem"
                rpadding="0.3rem"
                rfontSize="0.8rem"
                border="1px solid #d0d1d2"
              >
                Sign up{" "}
              </Button>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default SignUpSendEmail;

const Img = styled.img`
  width: 40%;

  @media screen and (max-width: 1023px) {
    width: 30%;
  }
`;
const SvgImg = styled.img`
  width: 3rem;
`;

