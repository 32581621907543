import React, { useEffect } from "react";
import Wrapper from "../components/common/Wrapper";
import UserCard from "../components/UserCard/UserCard";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPatientList } from "../service/actions/Profile/getPatientList";
import { getReport } from "../service/actions/Profile/getReport";

const UserList = () => {
  const navigate = useNavigate();
const dispatch = useDispatch()
  const infoHandler = (id) => {
 
    navigate(`/dashboard/user-list/${id}`);
    console.log("iddd", id);
  };


  const data = useSelector((state) => state?.getPatientList?.listArray)
  console.log("newdata", data);
  useEffect(() => {
    dispatch(getPatientList())
  }, [dispatch])
  return (
    <Wrapper direction="column" padding="3rem 0" >
      <Wrapper width="80%" rwidth="90%" direction="column" gap="1rem">
        {data?.map((item) => (
          <Wrapper onClick={() => infoHandler(item?.id)} cursor="pointer">

            <UserCard
              id={item?.id}
              age={item?.age}
              name={item?.name}
              gender={item?.gender}
            />
          </Wrapper>
        ))}
      </Wrapper>
    </Wrapper>
  );
};

export default UserList;
