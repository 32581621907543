import * as api from "../../../server/index";

export const signUpSendEmail = (signUpEmail) => async (dispatch) => {
  dispatch({ type: "FETCH_SIGNUP_EMAIL_LOADING" });

  try {
    const { data } = await api.signUpSendEmail(signUpEmail);
    dispatch({ type: "FETCH_SIGNUP_EMAIL_DONE", payload: data });
   
    localStorage.setItem("mentSignUpEmail", JSON.stringify(signUpEmail));
    localStorage.setItem("mentSignUpOtp", JSON.stringify( data?.data));
   
    window.location.href = '/signup-otp'  


  } catch (error) {
    dispatch({ type: "FETCH_SIGNUP_EMAIL_ERROR", payload: error });
  }
};
