import * as api from "../../../server/index";

export const getReport = (userInf) => async (dispatch) => {
  dispatch({ type: "FETCH_GET_REPORT_LOADING" });

  try {
    const { data } = await api.getreport(userInf);
    dispatch({ type: "FETCH_GET_REPORT_DONE", payload : data?.data });

  } catch (error) {
    dispatch({ type: "FETCH_GET_REPORT_FAILED" });
    console.log("ERROR");
  }
};
