import React from 'react'
import Wrapper from '../components/common/Wrapper'
import styled from 'styled-components'

const Dashboard = () => {
  return (
    <Wrapper direction="column">
      <strong>

dashboard
      </strong>
      <Line/>


  </Wrapper>
  )
}

export default Dashboard

const Line = styled.hr`
  color: rgba(0, 0, 0, 0.65);
  margin-left: -100;
;
`;