import * as api from "../../../server/index";
import { Button, Divider, notification, Space } from 'antd';

export const sendLoginInfo = (loginInfo) => async (dispatch) => {
  dispatch({ type: "FETCH_LOGIN_LOADING" });

  try {
    const { data } = await api.login(loginInfo);
    dispatch({ type: "FETCH_LOGIN_DONE" });
    localStorage.setItem("mentaliganceEmail", JSON.stringify(loginInfo));
    localStorage.setItem("mentaliganceOtp", JSON.stringify(data?.data));
    console.log("LOG_MEN", data);
    window.location.href = "/otp";



    // notification.success({
    //   message: 'Login Successful',
    //   description: 'You have successfully logged in.',
    //   placement: 'bottomLeft',
    // });

  } catch (error) {
    dispatch({ type: "FETCH_LOGIN_FAILED" });
    console.log("ERROR",error?.response);
    notification.error({
      message: 'Login Failed',
      description: `${error?.response?.data?.message}`,
      placement: 'bottomLeft',
    });

  }
};
