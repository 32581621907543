import { combineReducers } from "redux";

import { loginReducer } from "./Login/loginReducer";
import { logoutReducer } from "./Login/logoutReducer";

import { getProfileReducer } from "./Profile/getProfileReducer";
import { updateProfileReducer } from "./Profile/updateProfileReducer";

import { signUpSendEmailReducer } from "./SignUp/signUpSendEmailReducer";
import { sendOtpReducer } from "./Login/sendOtpReducer";
import { signUpSendOtpReducer } from "./SignUp/signUpSendOtpReducer";
import { signUpSendFinalizeReducer } from "./SignUp/signUpSendFinalizeReducer";
import { getPatientListReducer } from "./Profile/getPatientListReducer";
import { getReportReducer } from "./Profile/getReportReducer";
import { addConversationReducer } from "./Message/addConversationReducer";
import { getConversationListReducer } from "./Message/getConversationListReducer";
import { sendMessageReducer } from "./Message/sendMessageReducer";
import { getConversationInfoReducer } from "./Message/getConversationInfoReducer";
import { getCompanyCodeReducer } from "./CompanyCode/getCompanyCodeReducer";

export const rootReducer = combineReducers({
  login: loginReducer,
  otp: sendOtpReducer,
  signUpEmail: signUpSendEmailReducer,
  signUpOtp: signUpSendOtpReducer,
  signUpFinalize : signUpSendFinalizeReducer,
  logout: logoutReducer,
  getPatientList : getPatientListReducer,
  getReport : getReportReducer,
  addConversation:addConversationReducer,
  getConversationList : getConversationListReducer,
  getConversationInfo :getConversationInfoReducer,
  sendMessage : sendMessageReducer,

  getProfile: getProfileReducer,
  updateProfile: updateProfileReducer,

  getCompanyCode : getCompanyCodeReducer,
});
