import * as api from "../../../server/index";

export const sendOtp = (otpInfo) => async (dispatch) => {
  dispatch({ type: "FETCH_OTP_LOADING" });

  try {
    const { data } = await api.sendOtp(otpInfo);
    dispatch({ type: "FETCH_OTP_DONE" });
    localStorage.setItem("mentaliganceInfo", JSON.stringify(data?.data));
    localStorage.removeItem('mentaliganceEmail');
    localStorage.removeItem('mentaliganceOtp');

    console.log("data", data?.data);
    
    window.location.href = '/dashboard'  

  } catch (error) {
    dispatch({ type: "FETCH_OTP_FAILED" });
    console.log("ERROR");
  }
};
