import React, { useState } from "react";
import Wrapper from "../components/common/Wrapper";
import Input from "../components/common/Input";
//icon

import styled from "styled-components";
import Button from "../components/common/Button";
//
import { useDispatch, useSelector } from "react-redux";
import { sendLoginInfo } from "../service/actions/Profile/login";

import Loading from "../utils/Loading";
import Span from "../components/common/Span";
import { useNavigate } from "react-router";
import { sendOtp } from "../service/actions/Profile/sendOtp";

const Otp = () => {
  const dispatch = useDispatch();
  const otpStatus = useSelector((state) => state?.otp?.status);
  const email = JSON.parse(localStorage.getItem("mentaliganceEmail"))?.email;
  const otpResponse = JSON.parse(localStorage.getItem("mentaliganceOtp"));
  console.log("otpResponse", otpResponse);

  const navigate = useNavigate();
  const signUpHandler = () => {
    navigate("/signup");
  };
  const [otpInfo, setotpInfo] = useState({
    email,
    code: "",
  });
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setotpInfo({
      ...otpInfo,
      [name]: value,
    });
  };

  const fetchotpInfo = () => {
    dispatch(sendOtp(otpInfo));
    console.log("loggiiin", otpInfo);
  };
  return (
    <Wrapper background="rgb(223 230 233)">
      {otpStatus === "pending" && <Loading />}
      <Wrapper height="100vh">
        <Wrapper
          direction="column"
          width="25%"
          // height="60%"
          justifyContent="space-between"
          padding="1rem"
          rwidth="85%"
          // rheight="60%"
          rjustifyContent="space-evenly"
          borderRadius="1rem"
          rpadding="0.5rem"
          rdirection="column"
          rmargin="25% auto"
          border="1px solid #000"
          background="rgb(255,255,255)"
        >
          <Wrapper
            width="100%"
            rwidth="100%"
            height="100%"
            borderRadius="1rem"
            direction="column"
            rdirection="column"
            justifyContent="space-evenly"
            rjustifyContent="space-evenly"
            gap="3rem"
          >
            <Wrapper>
              <Span size="2rem" weight="bold">
                OTP Code
              </Span>
            </Wrapper>

            <Wrapper
              width="80%"
              direction="column"
              rdirection="column"
              gap="3rem"
              padding="1rem"
              rpadding="1rem"
            >
              <Wrapper
                width="95%"
                justifyContent="space-between"
                rjustifyContent="space-between"
                // borderBottom="1px solid #000"
                rwidth="95%"
                direction="column"
                rdirection="column"
                // background="red"
                // padding="0.5rem 0"
                gap="1rem"
              >
                {/* <PiUser color="#000" /> */}

                <Wrapper
                  justifyContent="flex-start"
                  rjustifyContent="flex-start"
                >
                  <Span weight="bold">Otp</Span>
                </Wrapper>
                <Wrapper borderBottom="1px solid #000">
                  <Input
                    value={otpInfo.code}
                    name="code"
                    type="text"
                    placeholder="Type your Otp Code"
                    width="95%"
                    padding="0 0 0.5rem 3.5rem"
                    Rpadding="0.4rem"
                    Rwidth="95%"
                    onChange={changeHandler}
                    color="#000"
                    autoComplete="off"
                  />
                </Wrapper>
                <Wrapper justifyContent="flex-start">
                  {otpResponse ? (
                    <Span color="rgb(112, 112, 112)">OTP : {otpResponse}</Span>
                  ) : null}
                </Wrapper>
              </Wrapper>
            </Wrapper>

            <Wrapper
              width="80%"
              justifyContent="space-evenly"
              rjustifyContent="space-evenly"
            >
              <Button
                onClick={fetchotpInfo}
                background="rgb(11 66 108)"
                width="65%"
                color="#fff"
                borderRadius="2rem"
                rwidth="50%"
                padding="0.5rem"
                rpadding="0.3rem"
                rfontSize="0.8rem"
                border="1px solid #d0d1d2"
              >
                send OTP{" "}
              </Button>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default Otp;

const Img = styled.img`
  width: 40%;

  @media screen and (max-width: 1023px) {
    width: 30%;
  }
`;
