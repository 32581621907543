import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import styled from "styled-components";
import Span from "../common/Span";
import Wrapper from "../common/Wrapper";

// icons
import { RxDashboard } from "react-icons/rx";
import { AiOutlineUser } from "react-icons/ai";
// import  {SlChart} from "react-icons/sl"
import { BsEnvelope } from "react-icons/bs";
import { AiOutlinePoweroff } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../service/actions/Profile/logout";
import { getCompanyCode } from "../../service/actions/CompanyCode/getCompanyCode";

const Sidebar = () => {
  const dispatch =useDispatch()
  const location = useLocation();
const navigate = useNavigate()
  const [showDashboard, setShowDashboard] = useState(false);
  const pathnames = location.pathname.split("/").filter((x) => x);
  const newPath = pathnames[pathnames.length - 1];
const logOutHandler = () => {
  dispatch(logout())
  // logout
}
const companyCode = useSelector((state) => state?.getCompanyCode?.listArray)

  useEffect(() => {
dispatch(getCompanyCode())
  },[])
  return (
    <Wrapper
      background="#fff"
      height="100%"
      width="20%"
      rwidth="20%"
      direction="column"
      
      alignItem="flex-start"
      justifyContent="space-between"
      rjustifyContent="space-between"
      ralignItem="flex-start"


      borderRight="1px solid #000"
    >
      <Wrapper direction="column" rdirection="column">
        <Wrapper
          borderBottom="1px solid #000"
          justifyContent="flex-start"
          rjustifyContent="flex-start"
          >
          <Span padding="1rem" size="1.5rem" color="blue">
            Mentaligence
          </Span>
        </Wrapper>

        <Wrapper  justifyContent="flex-start" rjustifyContent="flex-start" >
          <Span padding="0.3rem 1rem" size="1.5rem">
            Menu
          </Span>
        </Wrapper>

        <Wrapper
          rwidth="80%"
          width="80%"
          justifyContent="flex-start"
          rjustifyContent="flex-start"
          cursor="pointer"
          direction="column"
          rdirection="column"
          onClick={() => setShowDashboard(!showDashboard)}
        >
          <Wrapper>
            <Wrapper gap="0.5rem">
              <RxDashboard color="rgb( 59 86 110)" />
              <Span color="rgb( 59 86 110)" size="1.3rem" rsize="1rem">
                Dashboard{" "}
              </Span>
            </Wrapper>
            <Wrapper justifyContent="flex-end" rjustifyContent="flex-end">
              <Span size="1.5rem" color="rgb( 59 86 110)">
                +
              </Span>
            </Wrapper>
          </Wrapper>

          {showDashboard && (
            <Wrapper
              direction="column"
              gap="0.5rem"
              padding="0.5rem"
              rdirection="column"
            >
              <Wrapper
                width="80%"
                justifyContent="flex-start"
                rjustifyContent="flex-start"
                rwidth="80%"
                gap="0.5rem"
                cursor="pointer"
                onClick={() => navigate("/dashboard/user-list")}
              >
                <AiOutlineUser color="rgb( 59 86 110)"/>
                <StyledLink
                  color="rgb( 59 86 110)"
                  size="1.1rem"
                  to="/dashboard/user-list"
                >
                  User List
                </StyledLink>
              </Wrapper>

              {/* <Wrapper
                width="80%"
                justifyContent="flex-start"
                rwidth="80%"
                rjustifyContent="flex-start"
                gap="0.5rem"
              >
                <BsEnvelope color="rgb( 59 86 110)"/>
                <StyledLink
                  color="rgb( 59 86 110)"
                  size="1.1rem"
                  to="/dashboard/message"
                >
                  Messages
                </StyledLink>
              </Wrapper> */}
            </Wrapper>
          )}
        </Wrapper>

        <Wrapper
          // padding="1rem 0"
          // background={newPath === "reserve" ? "#d0d1d2" : "none"}
          width="80%"
          justifyContent="flex-start"
          rwidth="80%"
          rjustifyContent="flex-start"
          gap="0.5rem"
        >
          <AiOutlinePoweroff color="rgb( 59 86 110)"/>
          <Span
          size="1.3rem"
          color="rgb(59 86 110)"
          cursor="pointer"
          onClick={logOutHandler}
          >
            {" "}
            Log Out{" "}
          </Span>
        </Wrapper>



      </Wrapper>
      <Wrapper justifyContent="flex-start" rjustifyContent="flex-start" padding="0.5rem"><Span>CompanyCode :{" "}{companyCode}</Span></Wrapper>
    </Wrapper>
  );
};

export default Sidebar;

 const StyledLink = styled(Link)`
  text-decoration: none;
  /* color: #112D4E; */
  transition: all 0.5s ease;
  font-weight: ${(props) => (props.isActive ? "bold" : "none")};
  /* color: ${(props) => (props.isActive ? "#112D4E" : "#fff")}; */
  color: rgb(59 86 110);
  font-size: 1.3rem;
  &:hover {
    /* color: #235789; */
    /* background-color: #d0d1d2; */
    /* font-size: 1.1rem; */
    /* padding: 0.2rem; */
    /* font-weight: bold; */
    /* letter-spacing: 0.1em; */
  }

  @media screen and (max-width: 1023px) {
    font-size: 0.8rem;
    /* font-weight: bold; */
    &:hover {
      font-size: 0.8rem;
      font-weight: bold;
      padding-bottom: 0.01rem;
    }
  }
`;
