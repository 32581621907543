import * as api from "../../../server/index";

export const addConversation = (conversationInf) => async (dispatch) => {
  dispatch({ type: "FETCH_ADD_CONVERSATION_LOADING" });

  try {
    const { data } = await api.addConversation(conversationInf);
    dispatch({ type: "FETCH_ADD_CONVERSATION_DONE" });
   window.location.reload()
  } catch (error) {
    dispatch({ type: "FETCH_ADD_CONVERSATION_FAILED" });
    console.log("ERROR");
  }
};
  