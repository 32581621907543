const getLocalStorage = () => {
    const data = localStorage.getItem("mentaliganceInfo");
    if (data) {
        return data;
    }
}



const isAuthenticated = () => {
    try {
        const token = getLocalStorage();
        const result = token ;
   
        return result;
    } catch (error) {
        return false;
    }
    // return true
}




export const auth = {isAuthenticated};
