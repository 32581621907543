import React from "react";
import styled from "styled-components";

const Input = React.forwardRef((props, ref) => {
  const {
    padding,
    margin,
    size,
    Rwidth,
    color,
    border,
    radius,
    onChange, 
    placeholder,
    value,
    type,
    accept,
    background,
    maxLength,
    width,
    name,
    defaultValue,
    Rpadding,
    autoFocus,
    borderRadius,
    borderBottom,
    id,
    className
  } = props;

  return (
    <InputField
    className={className}
    id={id}
      padding={padding}
      margin={margin}
      accept={accept}
      color={color}
      background={background}
      radius={radius}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      name={name}
      type={type}
      ref={ref}
      width={width}
      Rwidth={Rwidth}
      border={border}
      borderRadius={borderRadius}
      size={size}
      defaultValue={defaultValue}
      maxLength={maxLength}
      Rpadding={Rpadding}
      autoFocus={autoFocus}
      borderBottom={borderBottom}
    />
  );
});

export default Input; 

export const InputField = styled.input.attrs((props) => ({
  maxLength: props.maxLength,
  autoComplete: props.autoComplete || "off",
}))`
  background-color: ${({ background }) =>
    background ? background : "transparent"};
  // box-shadow: ${({ theme }) => theme.inputBoxShadow};
  width: ${({ width }) => (width ? width : null)};
  border: ${({ border }) => (border ? border : "none")};
  padding: ${({ padding }) => (padding ? padding : "")};
  outline: none;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "none"};
  text-align: start;
  font-size: ${({ size }) => (size ? size : "1rem")};
  color: ${({ color }) => (color ? color : "black")};
  /* transition: border-color 0.3s ease; */
  border-bottom: ${({ borderBottom }) => (borderBottom ? borderBottom : null)};
resize: vertical;
  &:hover {
    /* border-bottom: 1px solid lightblue; */
  }

  &:focus {
    /* border-bottom: 1px solid blue; */
  }
  &::placeholder {
    ${({ placeholder }) => (placeholder ? placeholder : "")}
    font-size: 1rem;
    text-align: start;
    /* font-weight: bold; */
    color: rgb(112, 112, 112);

    @media screen and (max-width: 1023px) {
      font-size: 0.8rem;
      padding: 0 0.1rem;
    }
  }

  @media screen and (max-width: 1023px) {
    width: ${({ Rwidth }) => (Rwidth ? Rwidth : null)};
    padding: ${({ Rpadding }) => (Rpadding ? Rpadding : "")};
  }
`;
