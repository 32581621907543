import React, { useEffect, useState } from "react";
import Wrapper from "../components/common/Wrapper";
import Input from "../components/common/Input";
//icon

import styled from "styled-components";
import Button from "../components/common/Button";
//
import { useDispatch, useSelector } from "react-redux";
import { sendLoginInfo } from "../service/actions/Profile/login";

import Loading from "../utils/Loading";
import Span from "../components/common/Span";
import { useNavigate } from "react-router";

const Login = () => {
  const dispatch = useDispatch();
  const loginStatus = useSelector((state) => state?.login?.status);
  console.log("loginStatus", loginStatus);
  const navigate = useNavigate();
  const signUpHandler = () => {
    navigate("/signup-email");
  };
  const [loginInfo, setLoginInfo] = useState({
    email: "",
  });
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setLoginInfo({
      ...loginInfo,
      [name]: value,
    });
  };

  const fetchLoginInfo = () => {
    dispatch(sendLoginInfo(loginInfo));
  
    console.log("loggiiin", loginInfo);
  };

  return (
    <Wrapper background="rgb(223 230 233)">
      {loginStatus === "pending" && <Loading />}
      <Wrapper height="100vh">
        <Wrapper
          direction="column"
          width="25%"
          // height="60%"
          justifyContent="space-between"
          padding="1rem"
          rwidth="85%"
          // rheight="60%"
          rjustifyContent="space-evenly"
          borderRadius="1rem"
          rpadding="0.5rem"
          rdirection="column"
          rmargin="25% auto"
          border="1px solid #000"
          background="rgb(255,255,255)"
        >
          <Wrapper
            width="100%"
            rwidth="100%"
            height="100%"
            borderRadius="1rem"
            direction="column"
            rdirection="column"
            justifyContent="space-evenly"
            rjustifyContent="space-evenly"
            gap="3rem"
          >
            <Wrapper>
              <Span size="2rem" weight="bold">
                Login
              </Span>
            </Wrapper>

            <Wrapper
              width="80%"
              direction="column"
              rdirection="column"
              gap="3rem"
              padding="1rem"
              rpadding="1rem"
            >
              <Wrapper
                width="95%"
                justifyContent="space-between"
                rjustifyContent="space-between"
                // borderBottom="1px solid #000"
                rwidth="95%"
                direction="column"
                rdirection="column"
                // background="red"
                // padding="0.5rem 0"
                gap="1rem"
              >
                {/* <PiUser color="#000" /> */}

                <Wrapper
                  justifyContent="flex-start"
                  rjustifyContent="flex-start"
                >
                  <Span weight="bold">Username</Span>
                </Wrapper>
                <Wrapper borderBottom="1px solid #000">
                  <Input
                    value={loginInfo.email}
                    name="email"
                    type="text"
                    placeholder="Type your username"
                    width="95%"
                    padding="0 0 0.5rem 3.5rem"
                    Rpadding="0.4rem"
                    Rwidth="95%"
                    onChange={changeHandler}
                    color="#000"
                    autoComplete="off"
                  />
                </Wrapper>
              </Wrapper>
            </Wrapper>

            <Wrapper
              width="80%"
              justifyContent="space-evenly"
              rjustifyContent="space-evenly"
            >
              <Button
                onClick={fetchLoginInfo}
                background="rgb(11 66 108)"
                width="65%"
                color="#fff"
                borderRadius="2rem"
                rwidth="50%"
                padding="0.5rem"
                rpadding="0.3rem"
                rfontSize="0.8rem"
                border="1px solid #d0d1d2"
              >
                Login{" "}
              </Button>
            </Wrapper>

            <Wrapper>
              <Span
                onClick={signUpHandler}
                cursor="pointer"
                textDecoration="underline"
              >
                Sign up
              </Span>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default Login;

const Img = styled.img`
  width: 40%;

  @media screen and (max-width: 1023px) {
    width: 30%;
  }
`;
