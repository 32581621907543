import * as api from "../../../server/index";

export const getCompanyCode = () => async (dispatch) => {
  dispatch({ type: "FETCH_GET_COMPANY_CODE_LOADING" });
  try {
    const { data } = await api.getCompanyCode();
    dispatch({ type: "FETCH_GET_COMPANY_CODE_DONE", payload: data?.data });
  } catch (error) {
    dispatch({ type: "FETCH_COMPANY_CODE_FAILED" });
  }
};
