export const initialState = {
    status: 'idle',
    // message: '',
  }
  
  export const signUpSendEmailReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_SIGNUP_EMAIL_LOADING':
        return {
          ...state,
          status: 'pending',
  
        }
      case 'FETCH_SIGNUP_EMAIL_DONE':
        return {
          ...state,
          status: 'success',
        //   message: action.payload,
        }
      case 'FETCH_SIGNUP_EMAIL_ERROR':
        return {
          ...state,
          status: 'error',
  
          // message: action.payload,
        }
  
      default:
        return state
    }
  }