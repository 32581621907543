import React from "react";
import styled from "styled-components";
import Wrapper from "../components/common/Wrapper";
import NotfoundImage from "../assets/images/404-page-not-found.svg";
import Typography from "../components/common/Typography";
import { Link } from "react-router-dom";
const NotFoundPage = () => {
  return (
    <Main >
      <Modal>
        <Wrapper direction="column" rdirection="column" rjustifyContent="flex-start" justifyContent="flex-start"  height="100vh">
          <Typography color="#24285b" weight="bold" size="1.8rem" rsize="1.5rem">
Page Notfound          </Typography>
          <Img src={NotfoundImage} />
          <StyledLink to="./">Back</StyledLink>
        </Wrapper>
      </Modal>
    </Main>
  );
};

export default NotFoundPage;

const Img = styled.img`
  width: 100%;
  height: 70%;

  @media screen and (max-width: 1023px) {
    width: 80% ;
    height: 60% ;
  }
`;

const Modal = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(9px);
  /* overflow: auto; */
  /* background: linear-gradient(
    107.84deg,
    rgba(255, 255, 255, 0.44) 0%,
    rgba(255, 255, 255, 0.0836) 100%
  ); */
  
  
  @media screen and (max-width: 1023px) {
    // padding: 0.8rem;
  }
  `;

const StyledLink = styled(Link)`
transition: all 0.5s ease;
  color: #24285b;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration:underline;
  &:hover {
    color: #235789;
    font-weight: bolder;
}
`;


export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  width: 100%;
  @media screen and (max-width: 1023px) {

    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: ${({ rdirection }) => (rdirection ? rdirection : "row")};
  }
`;