const initialState = {
    status: "idle",
    listArray: [],
  };
  
  export const getConversationListReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_GET_CONVERSATION_LIST_LOADING":
        return {
          ...state,
          status: "pending",
        };
      case "FETCH_GET_CONVERSATION_LIST_DONE":
        return {
          ...state,
          status: "success",
          listArray: action.payload,
        };
      case "FETCH_CONVERSATION_LIST_FAILED":
        return {
          ...state,
          status: "failed",
        };
      default:
        return state;
    }
  };
  