import * as api from "../../../server/index";

export const getPatientList = () => async (dispatch) => {
  dispatch({ type: "FETCH_GET_PATIENT_LIST_LOADING" });
  try {
    const {data} =  await api.getPatientList()
    dispatch({ type: "FETCH_GET_PATIENT_LIST_DONE" , payload : data?.data});
console.log("data", data);
  } catch (error) {
    dispatch({ type: "FETCH_PATIENT_LIST_FAILED" });

  }
};
