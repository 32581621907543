import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";

import { Suspense, lazy } from "react";
import Wrapper from "./components/common/Wrapper";
import Typography from "./components/common/Typography";
import Layout from "./components/Layout/Layout";
import { auth } from "./routes/auth";
import PrivateWrapper from "./routes/PrivateRoute";
import Login from "./pages/Login";
import SignUp from "./pages/SignUpSendEmail";
import NotFoundPage from "./pages/NotFoundPage";

import { useSelector } from "react-redux";
import DashboardLayout from "./components/Layout/DashboardLayout";
import Dashboard from "./pages/Dashboard";
import UserList from "./pages/UserList";
import MessagePage from "./pages/MessagePage";
import Otp from "./pages/Otp";
import SignUpSendEmail from "./pages/SignUpSendEmail";
import SignUpSendOtp from "./pages/SignUpSendOtp";
import FinalizeSignUp from "./pages/FinalizeSignUp";
import ChartPage from "./pages/ChartPage";

// const Home = lazy(() => import("./pages/Home"));
// const Cars = lazy(() => import("./pages/Cars"));
// const CarsDetials = lazy(() => import("./pages/CarsDetials"));

const LoadingFallback = () => (
  <Wrapper height="100vh" ralignItem="flex-start" alignItem="flex-start">
    <Typography color="#112D4E" size="1.5rem" weight="bold" rsize="0.8rem">
Loading...
    </Typography>
  </Wrapper>
);

//loading
function App() {
  const loading = useSelector((state) => state?.loading?.showLoading);
  console.log("loading", loading);
  return (
    <div>
      <Router>
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />

            <Route path="/login" element={<Login />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/signup-email" element={<SignUpSendEmail />} />
            <Route path="/signup-otp" element={<SignUpSendOtp />} />
            <Route path="/signup-finalize" element={<FinalizeSignUp />} />

            <Route path="*" element={<NotFoundPage />} />

            <Route
              element={
                auth.isAuthenticated() ? (
                  <PrivateWrapper />
                ) : (
                  <>
                    <Login />
                  </>
                )
              }
            >
              <Route
                path="/dashboard/*"
                element={
                    <DashboardLayout>
                      <Routes>
                        <Route index element={<Dashboard />} />
                        <Route path="*" element={<NotFoundPage />} />
                        <Route path="user-list" element={<UserList />} />
                        <Route path="message" element={<MessagePage />} />
                        <Route path="user-list/:id" element={<ChartPage/>} />


                      </Routes>
                    </DashboardLayout>
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;


