const initialState = {
    status: "idle",
    listArray :[]
  };
  
  export const getReportReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_GET_REPORT_LOADING":
        return {
          ...state,
          status: "pending",
        };
      case "FETCH_GET_REPORT_DONE":
        return {
          ...state,
          status: "success",
          listArray: action.payload,

        };
      case "FETCH_GET_REPORT_FAILED":
        return {
          ...state,
          status: "failed",
        };
      default:
        return state;
    }
  };
  