import React from 'react'
import styled from 'styled-components'

const Button = ({
  key,
  id,
  children,
  onClick, 
  cursor,
  display,

  color,
  border,
  fontSize,
  backgroundLinear,
  weight,

  position,
  top,
  bottom,
  right, 
  left,

  borderRadius,
  boxShadow,
  height,
  direction,
  width,
  gap,
  padding,
  margin,
  background,
  alignItem,
  justifyContent,
  textDirection,

  msheight,
  msdirection,
  mswidth,
  msgap,
  mspadding,
  msmargin,
  msbackground,
  msalignItem,
  msjustifyContent,
  mstextDirection,
  msfontSize,

  mlheight,
  mldirection,
  mlwidth,
  mlgap,
  mlpadding,
  mlmargin,
  mlbackground,
  mlalignItem,
  mljustifyContent,
  mltextDirection,
  mlfontSize,

  mxheight,
  mxdirection,
  mxwidth,
  mxgap,
  mxpadding,
  mxmargin,
  mxbackground,
  mxalignItem,
  mxjustifyContent,
  mxtextDirection,
  mxfontSize,

  theight,
  tdirection,
  twidth,
  tgap,
  tpadding,
  tmargin,
  tbackground,
  talignItem,
  tjustifyContent,
  ttextDirection,
  tfontSize,

  rdirection,
  ralignItem,
  rwidth,
  rjustifyContent,
  rbackground,
  rheight,
  rpadding,
  rmargin,
  rgap,
  rborderRadius,
  ralignContennt,
  rfontSize,
  Zindex,
}) => {
  return (
    <Container
      key={key}
      id={id}
      display={display}
      onClick={onClick}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      color={color}
      fontSize={fontSize}
      height={height}
      direction={direction}
      width={width}
      gap={gap}
      padding={padding}
      margin={margin}
      background={background}
      backgroundLinear={backgroundLinear}
      alignItem={alignItem}
      justifyContent={justifyContent}
      textDirection={textDirection}
      border={border}
      cursor={cursor}
      weight={weight}
      position={position}
      top={top}
      bottom={bottom}
      right={right}
      left={left}
      msheight={msheight}
      msdirection={msdirection}
      mswidth={mswidth}
      msgap={msgap}
      mspadding={mspadding}
      msmargin={msmargin}
      msbackground={msbackground}
      msalignItem={msalignItem}
      msjustifyContent={msjustifyContent}
      mstextDirection={mstextDirection}
      msfontSize={msfontSize}
      mlheight={mlheight}
      mldirection={mldirection}
      mlwidth={mlwidth}
      mlgap={mlgap}
      mlpadding={mlpadding}
      mlmargin={mlmargin}
      mlbackground={mlbackground}
      mlalignItem={mlalignItem}
      mljustifyContent={mljustifyContent}
      mltextDirection={mltextDirection}
      mlfontSize={mlfontSize}
      mxheight={mxheight}
      mxdirection={mxdirection}
      mxwidth={mxwidth}
      mxgap={mxgap}
      mxpadding={mxpadding}
      mxmargin={mxmargin}
      mxbackground={mxbackground}
      mxalignItem={mxalignItem}
      mxjustifyContent={mxjustifyContent}
      mxtextDirection={mxtextDirection}
      mxfontSize={mxfontSize}
      theight={theight}
      tdirection={tdirection}
      twidth={twidth}
      tgap={tgap}
      tpadding={tpadding}
      tmargin={tmargin}
      tbackground={tbackground}
      talignItem={talignItem}
      tjustifyContent={tjustifyContent}
      ttextDirection={ttextDirection}
      tfontSize={tfontSize}
      rdirection={rdirection}
      ralignItem={ralignItem}
      rjustifyContent={rjustifyContent}
      rwidth={rwidth}
      rbackground={rbackground}
      rheight={rheight}
      rpadding={rpadding}
      rmargin={rmargin}
      rgap={rgap}
      rborderRadius={rborderRadius}
      ralignContennt={ralignContennt}
      rfontSize={rfontSize}
      Zindex={Zindex}
    >
      {children}
    </Container>
  )
}
export default Button

export const Container = styled.button`
  display: ${({ display }) => (display ? display : 'flex')};

  cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1rem')};
  border: ${({ border }) => (border ? border : 'none')};
  color: ${({ color }) => (color ? color : 'black')};
  width: ${({ width }) => (width ? width : '100%')};
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
  align-items: ${({ alignItem }) => (alignItem ? alignItem : 'center')};
  background-color: ${({ background }) => (background ? background : null)};
  margin: ${({ margin }) => (margin ? margin : null)};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : null)};
  padding: ${({ padding }) => (padding ? padding : null)};
  gap: ${({ gap }) => (gap ? gap : null)};
  z-index: ${({ Zindex }) => (Zindex ? Zindex : null)};
  direction: ${({ textDirection }) => (textDirection ? textDirection : 'null')};
  height: ${({ height }) => (height ? height : null)};
  box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : null)};

  font-weight: ${({ weight }) => (weight ? weight : '500')};

  position: ${({ position }) => (position ? position : null)};
  background: ${({ backgroundLinear }) => (backgroundLinear ? backgroundLinear : null)};
  top: ${({ top }) => (top ? top : null)};
  left: ${({ left }) => (left ? left : null)};
  bottom: ${({ bottom }) => (bottom ? bottom : null)};
  right: ${({ right }) => (right ? right : null)};

  //Responsive

  //ms
  @media screen and (max-width: 319px) {
    flex-direction: ${({ msdirection }) => (msdirection ? msdirection : 'row')};
    justify-content: ${({ msjustifyContent }) => (msjustifyContent ? msjustifyContent : 'center')};
    align-items: ${({ msalignItem }) => (msalignItem ? msalignItem : 'center')};
    background-color: ${({ msbackground }) => (msbackground ? msbackground : null)};
    margin: ${({ msmargin }) => (msmargin ? msmargin : null)};
    padding: ${({ mspadding }) => (mspadding ? mspadding : null)};
    gap: ${({ msgap }) => (msgap ? msgap : null)};
    width: ${({ mswidth }) => (mswidth ? mswidth : '100%')};
    direction: ${({ mstextDirection }) => (mstextDirection ? mstextDirection : 'null')};
    height: ${({ msheight }) => (msheight ? msheight : null)};
    font-size: ${({ msfontSize }) => (msfontSize ? msfontSize : '1rem')};
  }

  //ml
  @media screen and (min-width: 320px) and (max-width: 479px) {
    flex-direction: ${({ mldirection }) => (mldirection ? mldirection : 'row')};
    justify-content: ${({ mljustifyContent }) => (mljustifyContent ? mljustifyContent : 'center')};
    align-items: ${({ mlalignItem }) => (mlalignItem ? mlalignItem : 'center')};
    background-color: ${({ mlbackground }) => (mlbackground ? mlbackground : null)};
    margin: ${({ margin }) => (margin ? margin : null)};
    padding: ${({ mlpadding }) => (mlpadding ? mlpadding : null)};
    gap: ${({ gap }) => (gap ? gap : null)};
    width: ${({ mlwidth }) => (mlwidth ? mlwidth : '100%')};
    direction: ${({ mltextDirection }) => (mltextDirection ? mltextDirection : 'null')};
    height: ${({ mlheight }) => (mlheight ? mlheight : null)};
    font-size: ${({ mlfontSize }) => (mlfontSize ? mlfontSize : '1rem')};
  }

  //mx
  @media screen and (min-width: 480px) and (max-width: 767px) {
    flex-direction: ${({ mxdirection }) => (mxdirection ? mxdirection : 'row')};
    justify-content: ${({ mxjustifyContent }) => (mxjustifyContent ? mxjustifyContent : 'center')};
    align-items: ${({ mxalignItem }) => (mxalignItem ? mxalignItem : 'center')};
    background-color: ${({ mxbackground }) => (mxbackground ? mxbackground : null)};
    margin: ${({ margin }) => (margin ? margin : null)};
    padding: ${({ mxpadding }) => (mxpadding ? mxpadding : null)};
    gap: ${({ mxgap }) => (mxgap ? mxgap : null)};
    width: ${({ mxwidth }) => (mxwidth ? mxwidth : '100%')};
    direction: ${({ mxtextDirection }) => (mxtextDirection ? mxtextDirection : 'null')};
    height: ${({ mxheight }) => (mxheight ? mxheight : null)};

    font-size: ${({ mxfontSize }) => (mxfontSize ? mxfontSize : '1rem')};
  }

  //t
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    flex-direction: ${({ tdirection }) => (tdirection ? tdirection : 'row')};
    justify-content: ${({ tjustifyContent }) => (tjustifyContent ? tjustifyContent : 'center')};
    align-items: ${({ talignItem }) => (talignItem ? talignItem : 'center')};
    background-color: ${({ tbackground }) => (tbackground ? tbackground : null)};
    margin: ${({ tmargin }) => (tmargin ? tmargin : null)};
    padding: ${({ tpadding }) => (tpadding ? tpadding : null)};
    gap: ${({ tgap }) => (tgap ? tgap : null)};
    width: ${({ twidth }) => (twidth ? twidth : '100%')};
    direction: ${({ ttextDirection }) => (ttextDirection ? ttextDirection : 'null')};
    height: ${({ theight }) => (theight ? theight : null)};
    font-size: ${({ tfontSize }) => (tfontSize ? tfontSize : '1rem')}; 
  }

  //r
  @media screen and (max-width: 1023px) {
    display: ${({ rDisplay }) => (rDisplay ? rDisplay : 'flex')};
    flex-direction: ${({ rdirection }) => (rdirection ? rdirection : 'row')};
    align-items: ${({ ralignItem }) => (ralignItem ? ralignItem : 'center')};
    justify-content: ${({ rjustifyContent }) => (rjustifyContent ? rjustifyContent : 'center')};
    width: ${({ rwidth }) => (rwidth ? rwidth : '100%')};
    background-color: ${({ rbackground }) => (rbackground ? rbackground : null)};
    height: ${({ rheight }) => (rheight ? rheight : null)};
    padding: ${({ rpadding }) => (rpadding ? rpadding : null)};
    margin: ${({ rmargin }) => (rmargin ? rmargin : null)};
    gap: ${({ rgap }) => (rgap ? rgap : null)};
    border-radius: ${({ rborderRadius }) => (rborderRadius ? rborderRadius : null)};
    align-content: ${({ ralignContennt }) => (ralignContennt ? ralignContennt : null)};
    font-size: ${({ rfontSize }) => (rfontSize ? rfontSize : '1rem')};
  }
`
