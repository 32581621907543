import * as api from "../../../server/index";

export const getConversationInfo = (idInfo , userId) => async (dispatch) => {
  dispatch({ type: "FETCH_GET_CONVERSATION_INFO_LOADING" });
  try {
    const { data } = await api.getConversationInfo(idInfo , userId);
    dispatch({ type: "FETCH_GET_CONVERSATION_INFO_DONE", payload: data });

  } catch (error) {
    dispatch({ type: "FETCH_GET_CONVERSATION_INFO_FAILED" });
  }
};
